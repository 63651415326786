<template>
  <form-panel
    ref="formPanel"
    :form="item"
    :hasHeader="false"
    :footerShow="false"
    class="quTypeContent"
    v-if="!isView"
  >
    <el-form-item
      :label="'投票问题' + (index + 1)"
      :rules="[{ required: true, message: '请输入投票题目', trigger: 'blur' }]"
      prop="title"
      v-show="inputShow"
    >
      <v-input
        placeholder="请输入投票问题题目"
        :maxlength="150"
        v-model.trim="item.title"
        :width="450"
      ></v-input>
      <span v-show="item.quType === 4">【上传图片题】</span>
    </el-form-item>
    <template v-if="item.quType === 1 || item.quType === 2">
      <el-form-item
        v-for="(option, idx) in item.optionList"
        :key="'option' + idx"
        :label="idx === 0 ? '问题选项' : ''"
        required
      >
        <el-form-item
          style="display: inline-block"
          :rules="[
            {
              required: true,
              validator: optionValid,
              trigger: 'blur',
            },
          ]"
          :prop="`optionList.${idx}.optionTitle`"
        >
          <v-input
            placeholder="例如：A、B或1、2"
            :maxlength="20"
            :value="setAndReturnKey(option, idx)"
            :width="42"
            :disabled="true"
          ></v-input>
          <span class="optPadding">选项内容：</span>
          <v-input
            placeholder="请输入选项内容"
            :maxlength="150"
            v-model.trim="option.optionTitle"
            :width="272"
          ></v-input>
        </el-form-item>
        <v-button
          type="default"
          icon="el-icon-close"
          class="delOpt"
          circle
          @click="delOps(idx)"
          v-show="item.optionList.length > 2 && isShow"
        ></v-button>
      </el-form-item>
      <el-form-item v-show="isShow">
        <v-button
          text="+ 新增选项"
          @click="addOps"
          :width="250"
          size="small"
          plain
        ></v-button>
      </el-form-item>
    </template>
    <template v-else-if="item.quType === 4"></template>
    <el-form-item :label="item.quType === 2 ? '选项模式' : ''">
      <div class="multi-modal">
        <div style="display: flex" v-if="item.quType === 2">
          至少选
          <el-form-item
            style="display: inline-block"
            :rules="[
              {
                required: true,
                message: '请输入最小选项个数',
                trigger: 'blur',
              },
            ]"
            prop="minSelection"
          >
            <v-input-number
              v-model.trim="item.minSelection"
              class="optPadding"
              :width="100"
              :controls="true"
              controls-position="right"
              size="small"
              :min="minSelection"
              :max="item.maxSelection || 1"
              :disabled="!isShow"
            />
          </el-form-item>
          项，至多选
          <el-form-item
            style="display: inline-block"
            :rules="[
              {
                required: true,
                message: '请输入最大选项个数',
                trigger: 'blur',
              },
            ]"
            prop="maxSelection"
          >
            <v-input-number
              v-model.trim="item.maxSelection"
              class="optPadding"
              :width="100"
              :controls="true"
              controls-position="right"
              size="small"
              :min="item.minSelection || minSelection"
              :max="maxSelection"
              :disabled="!isShow"
            />
          </el-form-item>
          项
        </div>
        <v-button
          v-show="isShow"
          type="danger"
          @click="delQuestion"
          round
          plain
          icon="el-icon-delete"
          :class="item.quType === 2 ? 'delQue-multi' : 'delQue-single'"
          text="删除该问题"
        ></v-button>
        <!-- <v-button
          class="saveQue"
          v-show="!item.isSave"
          type="primary"
          @click="saveQuestion"
          round
          text="保存"
        ></v-button> -->
      </div>
    </el-form-item>
  </form-panel>
  <div v-else class="isView">
    <template v-if="item.quType === 1 || item.quType === 2">
      <div class="questionTitle">
        {{
          `${index + 1}、${item.title}${item.quType === 2 ? "【多选】" : ""}`
        }}
      </div>
      <ul class="optionList">
        <li v-for="(option, i) in item.optionList" :key="i">
          <div class="option">
            {{ `${option.optionKey}、${option.optionTitle}` }}
          </div>
          <div class="value">
            <span>选择人数：</span>
            <span class="count">{{ option.checkedCount }}</span>
            <span> | 占比：</span>
            <span class="scale">{{ getScale(option) }}</span>
            <!-- {{ `选择人数：${option.checkedCount} | 占比：${getScale(option)}` }} -->
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "quTypeConent",
  props: {
    // 参与人总数
    answerCount: {
      type: [String, Number],
    },
    //输入显示
    inputShow: {
      type: Boolean,
      default: true,
    },
    // 题目对象
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 题目对象集合
    listData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 索引
    index: {
      type: Number,
      default: 0,
    },
    // 是否可编辑
    isView: {
      type: Boolean,
    },
    //用户输入题目的信息
    stemInformation: {
      type: Object,
    },
  },
  data() {
    return {
      minSelection: 1,
      // 26个字母集合
      letters: [...Array(26).keys()].map((i) => String.fromCharCode(i + 65)),
    };
  },
  computed: {
    maxSelection() {
      return this.item.optionList.length;
    },
    // 通过是否已有参与人判断是否可删除问题或选项
    isShow() {
      let result = true;
      if (this.item.optionList) {
        this.item.optionList.forEach((opt) => {
          if (opt.checkedCount) {
            result = false;
          }
        });
      }
      console.log(this.answerCount, "this.answerCount");
      return result && this.answerCount === 0;
    },
    configData() {
      return {
        ...this.item,
      };
    },
  },
  watch: {
    configData: {
      handler(val) {
        this.$emit("update:stemInformation", val);
      },
    },
  },
  methods: {
    addOps() {
      this.item.optionList.push({ optionTitle: "", optionKey: "" });
    },
    delOps(index) {
      this.item.optionList.splice(index, 1);
    },
    delQuestion() {
      this.$emit("delQue", this.index);
      // 是否保存 如果保存调接口删除，反之直接删除
      // if (this.item.isSave) {
      //   //
      // } else {
      this.listData.splice(this.index, 1);
      // }
    },
    validate() {
      return this.$refs.formPanel.validate();
    },
    // saveQuestion(item) {
    //   this.$emit("saveQue", this.item);
    // },
    optionValid(rule, value, callback) {
      const fieldArr = rule.field.split(".");
      const optionTitle = this.item[fieldArr[0]][fieldArr[1]].optionTitle;
      const optionKey = this.item[fieldArr[0]][fieldArr[1]].optionKey;
      const isRepeat =
        this.item[fieldArr[0]]
          .map((v) => v.optionKey)
          .filter((key) => key === optionKey).length > 1;
      // console.log(
      //   this.item[fieldArr[0]].map((v) => v.optionKey),
      //   optionKey
      // );
      if (!optionTitle) {
        callback(new Error("请输入选项内容"));
      } else if (optionKey !== "0" && !optionKey) {
        callback(new Error("请输入选项值"));
      } else if (isRepeat) {
        callback(new Error("同一问题下选项值不可重复"));
      } else if (/,/.test(optionKey)) {
        callback(new Error("选项值不能存在‘,’"));
      } else {
        callback();
      }
    },
    getScale(opt) {
      // const total = this.item.optionList.reduce(
      //   (acc, cur) => acc + Number(cur.checkedCount) || 0,
      //   0
      // );
      console.log(this.answerCount, "this.answerCount");
      const total = this.answerCount;
      const cur = opt.checkedCount;
      return `${((opt.checkedCount / total || 0) * 100).toFixed(2)}%`;
    },
    setAndReturnKey(o, i) {
      this.$set(o, "optionKey", this.letters[i]);
      return o.optionKey;
    },
  },
};
</script>

<style lang="less" scoped>
.quTypeContent {
  .optPadding {
    padding: 0 10px;
    display: inline-block;
  }
  ::v-deep .v-button .el-button {
    min-width: 32px;
  }
  .multi-modal {
    display: flex;
  }
  .delOpt {
    margin-left: 10px;
  }
  .delQue-single {
    padding-left: 400px;
  }
  .delQue-multi {
    padding-left: 32px;
  }
  .saveQue {
    padding-left: 10px;
  }
}
.isView {
  word-wrap: break-word;
  word-break: break-all;
  .questionTitle {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-top: 12px;
  }
  .optionList {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    padding: 7px 40px 7px 16px;
    > li {
      display: flex;
      margin: 8px 0px 8px 0px;
      .option {
        width: 50%;
      }
      .value {
        margin-left: 24px;
        white-space: nowrap;
        .count {
          font-weight: bold;
        }
        .scale {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
