<template>
  <div class="answerInfo">
    <div class="main-wrap" :class="{ 'is-view': isView }">
      <div class="content" v-for="(item, index) in listData" :key="index">
        <qu-type-content
          :ref="`quTypeContent_${index}`"
          :listData="listData"
          :item="item"
          :answerCount="answerCount"
          :index="index"
          :isView="isView"
          :input-show="inputShow"
        ></qu-type-content>
      </div>
      <div
        class="create-btn"
        v-show="!isView"
        v-if="isReply ? (listData.length == 0 ? true : false) : false"
      >
        <v-button
          size="small"
          text="添加单选题"
          @click="addQustion(1)"
        ></v-button>
        <v-button
          size="small"
          text="添加多选题"
          @click="addQustion(2)"
        ></v-button>
        <!-- <v-button
        size="small"
        text="添加填空题"
        @click="addQustion(3)"
      ></v-button>
      <v-button
        size="small"
        text="添加 上传图片题"
        @click="addQustion(4)"
      ></v-button> -->
      </div>
    </div>
    <div class="footer-btn" v-if="listData.length > 0" v-show="buttonShow">
      <v-button
        v-show="!isView"
        v-loading="isLoading"
        class="btn"
        type="success"
        text="保存"
        @click="submit"
      ></v-button>
      <v-button class="btn" text="预览" plain @click="view"></v-button>
    </div>
  </div>
</template>

<script>
import { questionListURL, stemAddBatchURL } from "../api";
import quTypeContent from "./quTypeContent.vue";

export default {
  name: "answerInfo",
  components: {
    quTypeContent,
  },
  props: {
    id: {
      type: [String, Number],
    },
    // 参与人总数
    answerCount: {
      type: [String, Number],
    },
    //是否显示底部按钮
    buttonShow: {
      type: Boolean,
      default: true,
    },
    //是否显示再次添加按钮
    isReply: {
      type: Boolean,
      default: false,
    },
    //是否显示输入
    inputShow: {
      type: Boolean,
      default: true,
    },
    // 是否仅预览
    isView: {
      type: Boolean,
    },
    // 基础信息页面是否点击了保存
    saveKey: {
      type: Number,
    },
    //题目数据
    subject: {
      type: Object,
    },
    quType: [Number, String],
  },
  data() {
    return {
      // topicId: "", // 主题ID
      isLoading: false,
      msg: "", // 提示文字
      // deleteCurrent: 0, // 清空现有数据：1-是，0-否
      listData: [
        // {
        //   answer: "",
        //   quType: 1,
        //   title: "",
        //   topicId: 1,
        //   optionList: [
        //     {
        //       checkedCount: "",
        //       checkedPoints: "",
        //       content: "",
        //       id: "",
        //       optionKey: "A", // 选项Key
        //       optionTitle: "", // 标题
        //       stemId: "",
        //     },
        //     {},
        //   ],
        //   isSave: false,
        // },
        // {
        //   answer: "",
        //   maxSelection: "",
        //   minSelection: "",
        //   quType: 2,
        //   title: "",
        //   topicId: 1,
        //   optionList: [],
        //   isSave: false,
        // },
        // { answer: "", quType: 3, title: "", isSave: false },
        // { imageUrls: "", quType: 4, isSave: false },
      ], // 题目数据
    };
  },
  computed: {},
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.getDetail();
        }
      },
      immediate: true,
    },
    saveKey: {
      handler() {
        console.log(this.listData, "this.listData");
        this.listData = this.listData.filter((v) => v.isSave);
      },
    },
    subject: {
      handler(val) {
        if (this.quType) {
          this.listData.push(val);
        }
      },
    },
    listData: {
      handler(val) {
        if (this.isReply) {
          this.$emit("update:subjectBack", val[0]);
        }
      },
    },
  },
  methods: {
    // 添加题目
    addQustion(quType) {
      let question = {
        quType, // 选项类型
        title: "", // 题目标题
        topicId: this.id, // 对应题目id
        isSave: false, // 是否是已保存
      };
      if (quType === 1 || quType === 2) {
        question.optionList = [
          {
            // checkedCount: 0,
            // checkedPoints: "",
            content: "",
            // id: "",
            optionKey: "", // 选项Key
            optionTitle: "", // 标题
            // stemId: "",
          },
          {
            content: "",
            optionKey: "",
          },
        ];
      } else if (quType === 2) {
        question.maxSelection = undefined;
        question.minSelection = undefined;
      }
      this.listData.push(question);
    },
    // 获取详情
    getDetail() {
      this.$axios({
        url: questionListURL,
        method: "get",
        params: {
          curPage: 1,
          pageSize: 100000,
          topicId: this.id,
          userId: this.userId,
        },
      }).then((res) => {
        this.listData = res.data.records.map((v) => ({
          ...v,
          isSave: true,
        }));
      });
    },
    // 预览
    view() {
      this.$emit("view", this.listData);
    },
    // 提交
    submit() {
      if (!this.validResult()) {
        this.$message.warning(this.msg);
        return;
      }
      const listData = this.listData.map((v, i) => ({
        ...v,
        sortIndex: i + 1,
      }));
      this.isLoading = true;
      this.$axios({
        url: stemAddBatchURL,
        method: "post",
        data: {
          deleteCurrent: 1, // 清空现有数据：1-是，0-否
          stemString: JSON.stringify(listData),
          topicId: this.id,
        },
      })
        .then((res) => {
          if (res.code === 200) {
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 校验
    validResult() {
      const resultArr = this.listData.map((v, i) => {
        return this.$refs[`quTypeContent_${i}`][0].validate();
      });
      console.log(resultArr, "resultArr---");
      if (resultArr.length === 0) {
        this.msg = "请添加投票问题！";
        return false;
      }
      if (resultArr.length > 0) {
        let result = true;
        let index = undefined;
        resultArr.forEach((r, i) => {
          if (!r) {
            result = false;
            index = i;
          }
        });
        this.msg = !result ? `投票问题 ${index + 1} 存在格式错误！` : "";
        return result;
      }
      this.msg = "";
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.answerInfo {
  height: calc(100% - 41px);
  .main-wrap {
    height: calc(100% - 92px);
    overflow-x: hidden;
    overflow-y: auto;
    // &.is-view {
    //   height: calc(100% - 20px);
    // }
  }
  .create-btn {
    margin-top: 20px;
    display: flex;
    padding: 0 32px;
    .v-button {
      margin-right: 20px;
    }
  }
  .footer-btn {
    padding-left: 148px;
    margin: 32px 0 20px 0;
    .btn {
      min-width: 168px;
      margin-right: 32px;
    }
  }
}
</style>
