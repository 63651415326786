<template>
  <div class="edit-question">
    <form-panel
      ref="formPanel"
      :form="form"
      :query-url="stemDetails"
      @update="update"
    >
      <el-form-item
        label="题目"
        :rules="[{ required: true, message: '请输入题目', trigger: 'blur' }]"
        prop="title"
      >
        <v-input
          placeholder="请输入"
          :width="width"
          v-model="form.title"
        ></v-input>
      </el-form-item>
      <div class="answer-info-wrapper">
        <answer-info
          ref="answerInfo"
          :isView="isView"
          :isReply="true"
          :saveKey="saveKey"
          :answerCount="answerCount"
          :input-show="false"
          :button-show="false"
          :subject.sync="subject"
          :subjectBack.sync="subjectBack"
          :qu-type="quType"
        ></answer-info>
        <!-- <answer-info
          ref="answerInfo"
          :isView="isView"
          :saveKey="saveKey"
          :subject.sync="subject"
          :answerCount="answerCount"
        ></answer-info> -->
      </div>
      <el-form-item
        label="正确答案"
        :rules="[
          { required: true, message: '请输入正确答案', trigger: 'blur' },
        ]"
        prop="rightAnswer"
      >
        <v-input
          placeholder="请输入"
          :width="width"
          v-model="form.rightAnswer"
        />
      </el-form-item>
      <el-form-item
        label="图片"
        :rules="[{ required: false, message: '请上传图片', trigger: 'blur' }]"
        prop="selectStreet"
      >
        <v-upload :imgUrls.sync="form.imageUrls" :limit="1" />
      </el-form-item>
      <el-form-item
        label="发布状态"
        :rules="[
          { required: true, message: '请选择发布状态', trigger: 'blur' },
        ]"
        prop="shelfSts"
      >
        <v-select
          :width="width"
          :options="questionStatusList"
          v-model="form.shelfSts"
        />
      </el-form-item>
      <template #saveBeforeSlot>
        <v-button text="保存" @click="preservation"> </v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import AnswerInfo from "./components/answerInfo";
import { postStemAddOrEdit, stemDetails } from "./api";
import { questionStatusList } from "@/views/rubbishSort/question/questionConfig";

export default {
  name: "editQuestion",
  components: {
    AnswerInfo,
  },
  data() {
    return {
      width: 350,
      id: "",
      form: {
        title: null,
        rightAnswer: null,
        imageUrls: "",
        shelfSts: null,
      },
      saveKey: 0,
      isPreviewShow: false,
      answerCount: 0,
      isView: false,
      subject: null,
      subjectBack: null,
      questionStatusList: questionStatusList,
      stemDetails: stemDetails,
      quType: null,
    };
  },
  mounted() {
    const { id, quType } = this.$route.query;
    this.id = id;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ stemId: id });
      });
    }
    this.quType = quType;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    editReturn() {
      this.$router.back();
    },
    async preservation() {
      const child = document.getElementsByClassName("main-wrap")[0];
      const childList = [...child.childNodes];
      if (childList.length === 2) {
        const params = {
          id: this.id,
          title: this.form.title,
          rightAnswer: this.form.rightAnswer,
          imageUrls: this.form.imageUrls,
          quType: this.subjectBack.quType,
          optionList: this.subjectBack.optionList,
          shelfSts: this.form.shelfSts,
          maxSelection: this.subjectBack.maxSelection,
          minSelection: this.subjectBack.minSelection,
        };
        const res = await this.$axios({
          method: "post",
          url: postStemAddOrEdit,
          data: params,
        });
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        }
      } else {
        this.$message.error("只能有一个问题选项，请删除一个问题");
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.subject = data;
      this.subjectBack = data;
    },
  },
};
</script>
<style lang="less" scoped>
.edit-question {
  box-sizing: border-box;
  height: 100%;
  /deep/ .form-panel-container {
    .answer-info-wrapper {
      margin: 0 0 5vh 1.6vh;
      .answerInfo {
        .content {
          padding-right: 3vh;
        }
        .create-btn {
          margin-left: 6vh;
        }
      }
    }
  }
}
</style>
